<template>
  <v-btn
    v-if="createDelete"
    icon
    color="red lighten-2"
    class="mr-2"
    @click="deleteConfirm"
  >
    <v-icon>
      fa fa-trash
    </v-icon>
  </v-btn>
</template>

<script lang="babel" type="text/babel">
import treeNodeAction from '@/components/tree/nodeAction/treeNodeAction'
export default {
  mixins: [treeNodeAction],
  methods: {
    async deleteConfirm() {
      this.$apopup.base({
        title: this.$t(`delete.confirm`),
        applyCallback: this.removeRequest,
      })
    },
    async removeRequest() {
      try {
        await this.treeApi.deleteApi(this.item.id)
        this.$snotify.success(null, this.$t('delete.successfully') )
      } catch (error) {
        console.error(error)
        this.$snotify.error(
          this.$t('error.unexpect'),
          this.$t('delete.failure'),
        )
      } finally {
        this.clearMove()
        this.treeActions.refreshTree()
      }
    },
  },
  computed: {
    createDelete() {
      if(!this.hasWritePermission) return false
      if(!this.treeConfigActions) return true
      if(typeof this.treeConfigActions.delete != 'function') return true
      return this.treeConfigActions.delete(this.item, this.item.depth)
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>